//
// Main navbar
//

.td-navbar-cover {
    background: $primary;

    @include media-breakpoint-up(md) {
        background: transparent !important;

        .nav-link {
            text-shadow: 1px 1px 2px $dark;
        }

    }

    &.navbar-bg-onscroll .nav-link {
        text-shadow: none;
    }
}

.navbar-bg-onscroll {
    background: $primary !important;
    opacity: inherit;
}

.td-navbar {
    background: $primary;
    min-height: 4rem;
    margin: 0;
    z-index: 32;

    @include media-breakpoint-up(md) {
        position: fixed;
        top: 0;
        width: 100%;
    }

    .navbar-brand {
        text-transform: none;
        text-align: middle;

        &__name {
          font-weight: $font-weight-bold;
        }

        svg {
            display: inline-block;
            margin: 0 10px;
            height: 30px;
        }
    }

    .nav-link {
        text-transform: none;
        font-weight: $font-weight-bold;
    }

    .navbar-nav {
        white-space: nowrap;
    }

    // For .td-search__input styling, see _search.scss

    .dropdown {
        min-width: 100px;
    }

    @include media-breakpoint-down(md) {
        padding-right: .5rem;
        padding-left: .75rem;

        .td-navbar-nav-scroll {
            max-width: 100%;
            height: 2.5rem;
            margin-top: .25rem;
            overflow: hidden;
            font-size: .875rem;

            .nav-link {
                padding-right: .25rem;
                padding-left: 0;
            }

            .navbar-nav {
                padding-bottom: 2rem;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
            }
        }
    }
}

// Icons
#main_navbar {
    li i {
    padding-right: 0.5em;

        &:before {
            display: inline-block;
            text-align: center;
            min-width: 1em;
        }
    }
  .alert {
    background-color: inherit;
    padding:0;
    color: $secondary;
    border: 0;
    font-weight: inherit;

        &:before {
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: $font-awesome-font-name;
            font-weight: 900;
            content: "\f0d9";
            padding-left: 0.5em;
            padding-right: 0.5em;
        }
    }
}

// Foldable sidebar menu
nav.foldable-nav {

    &#td-section-nav {
        position: relative;
    }

    &#td-section-nav label {
        margin-bottom: 0;
        width: 100%;
    }

    .td-sidebar-nav__section, .with-child ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .ul-1 > li {
        padding-left: 1.5em;
    }

    ul.foldable {
        display: none;
    }

    input:checked ~ ul.foldable {
        display: block;
    }

    input[type=checkbox] { display: none; }

    .with-child, .without-child {
        position: relative;
        padding-left: 1.5em;
    }

    .ul-1 .with-child > label:before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: $font-awesome-font-name; font-weight: 900; content: "\f0da";
        position: absolute;
        left: 0.1em;
        padding-left: 0.4em;
        padding-right: 0.4em;
        font-size: 1em;
        color: $gray-900;
        transition: all 0.5s;
        &:hover{
        transform: rotate(90deg);
        }
    }

    .ul-1 .with-child > input:checked ~ label:before {
        color: $primary;
        transform: rotate(90deg);
        transition: transform 0.5s;
    }

    .with-child ul { margin-top: 0.1em; }

}

@media (hover: hover) and (pointer: fine) {

    nav.foldable-nav {

        .ul-1 .with-child > label:hover:before {
            color: $primary;
            transition: color 0.3s;
        }

        .ul-1 .with-child > input:checked ~ label:hover:before {
            color: $primary;
            transition: color 0.3s;
        }
    }
}
