// Code formatting.

.td-content {
	// Highlighted code.
    .highlight {
        @extend .card;
	
        margin: 2rem 0;
        padding: 0;
        position: relative;

        .click-to-copy {
            display: block;
            text-align: right;
            height: 1ex;
        }

        pre {
            margin: 0;
            padding: 1rem;

            // Default click-to-copy button

            button {
               position: absolute;
               color: $gray-400;
               border-radius: 3px;
               border-width: 0;
               background-color: inherit;
               box-shadow: 1px 1px $gray-400;
               right: 8px;
               top: 6px;

               &:hover {
                  color: $dark;
                  background-color: $gray-400;
               }
               &:active {
                  color: $dark;
                  background-color: $gray-400;
                  transform: translateY(2px);
               }
            }

            .c2c-tooltip {
               background: $dark;
               color: $white;
               padding: 2px 4px;
               border-radius: 3px;
               display: block;
               visibility: hidden;
               opacity: 0;
               transition: visibility 0s, opacity 0.5s linear;
            }

            .c2c-tooltip[show-tooltip] {
               visibility: visible;
               opacity: 1;
            }
        }
    }

    // Inline code
    p code, li > code, table code {
        color: inherit;
        padding: 0.2em 0.4em;
        margin: 0;
        font-size: 85%;
        word-break: normal;
        background-color: rgba($black, 0.05);
        border-radius: $border-radius;

        br {
            display: none;
        }
    }


    // Code blocks
    pre {
        word-wrap: normal;
        background-color: $gray-100;
        padding: $spacer;
     

        > code {
	    background-color: inherit !important;
            padding: 0;
            margin: 0;
            font-size: 100%;
            word-break: normal;
            white-space: pre;
            border: 0;
        }
    }

    pre.mermaid {
        background-color: inherit;
        font-size: 0;
    }
}
